export const howItsWorkCardArray = [
    {
        img: "/images/home-img/New folder/Expert_Faculty.png",
        alt: "sign up",
        count: 1,
        title: "EXPERT FACULTY",
        paragraph:"AT SOLLVERZ ACADEMY Experience unparalleled guidance with our expert faculty, dedicated to helping you achieve your ultimate goals.",
        border:"#A00069",
    },
    {
        img: "/images/home-img/New folder/interactive.png",
        alt: "scholarship",
        count: 2,
        title: "CUSTOMIZED LEARING ",
        paragraph:"AT SOLLVERZ ACADEMY Experience education that adapts to you, fostering growth and unlocking your full potential.",
    },
    {
        img: "/images/home-img/New folder/Comprehensive_Curriculum.png",
        alt: "scholarship",
        count: 3,
        title: "COMPREHENSIVE CURRICULUM",
        paragraph:"AT SOLLVERZ ACADEMY Experience a curriculum that integrates core subjects with real-world applications, fostering critical thinking and lifelong learning.",
        
        
    
    },
    {
        img: "/images/home-img/New folder/Interactive_Learning.png",
        alt: "learning",
        count: 4,
        title: "INTERACTIVE LEARNING",
        paragraph:"At Sollverz Academic, we embrace interactive learning to make education dynamic.Discover a learning environment where engagement and exploration are at the forefront, making learning both fun and effective.🚀",
       
    
    },
    {
        img: "/images/home-img/New folder/assessment.png",
        alt: "learning",
        count: 4,
        title: "Regular Assessment",
        paragraph:"At Sollverz Academic, regular assessments are integral to our approach, ensuring continuous progress.Stay on top of your learning journey with our consistent assessments, designed to guide you every step of the way. 🌟🚀",
       
    
    },
    {
        img: "/images/home-img/New folder/Supportive_Environment.png",
        alt: "learning",
        count: 4,
        title: "Supportive Environment",
        paragraph:"At Sollverz Academic, we foster a supportive environment where every student can thrive.Our dedicated faculty and staff create a nurturing atmosphere that encourages growth and personal development.",
     
    
    },
];
