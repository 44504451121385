import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ClassesPage from '../pages/public/classes/ClassesPage';
import ContactUsPage from '../pages/public/contact-us/ContactUsPage';
import EmailVerification from '../pages/public/EmailVerification';
import HomePage from '../pages/public/home/HomePage';
import MobileVerification from '../pages/public/MobileVerification';
import PricingPage from '../pages/public/pricing/PricingPage';
import ScholarshipPage from '../pages/public/scholarship/ScholarshipPage';
import SignUp from '../pages/public/SignUp';
import LogIn from '../pages/public/LogIn';
import About from '../pages/public/footer/About';
import Review from '../pages/public/footer/Review';
import Blogs from '../pages/public/footer/Blogs';
import News from '../pages/public/footer/News';
import Courses from '../pages/public/footer/Courses';

const PublicRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/classes" element={<ClassesPage />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/scholarship" element={<ScholarshipPage />} />
                <Route path="/contact-us" element={<ContactUsPage />} />
                <Route path="/mobile-verification" element={<MobileVerification />} />
                <Route path="/email-verification" element={<EmailVerification />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/login" element={<LogIn />} />
                <Route path="/about-us" element={<About />} />
                <Route path="/courses" element={<Courses />} />
                <Route path="/review" element={<Review />} />
                <Route path="/blog" element={<Blogs />} />
                <Route path="/news" element={<News />} />
            </Routes>
        </>
    )
}

export default PublicRoutes