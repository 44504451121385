import React, { useEffect, useState } from 'react';
import ClassesHeroSection from './ClassesHeroSection';
import { coursesArrayList } from '../../../arrays/course-page-array/coursesArrayList';
import ClassesCard from './ClassesCard';

const ClassesPage = () => {
    const [filteredClasses, setFilteredClasses] = useState([]);
    const [active, setActive] = useState({ preFoundation: "pre_foundation_active", foundation: "", mains: "" });

    //Filter Pre-Foundation Classes
    const preFoundationClasses = () => {
        setActive({ preFoundation: "pre_foundation_active" });
        const filteredPreFoundationClasses = coursesArrayList?.filter((ele) => {
            if (ele?.courseType === "pre-foundation") {
                return ele;
            }
        })
        setFilteredClasses(filteredPreFoundationClasses);
    }

    //Filter Foundation Classes
    const foundationClasses = () => {
        setActive({ foundation: "foundation_active" });
        const filteredFoundationClasses = coursesArrayList?.filter((ele) => {
            if (ele?.courseType === "foundation") {
                return ele;
            }

        })
        setFilteredClasses(filteredFoundationClasses);
    }

    //Filter Mains Classes
    const mainsClasses = () => {
        setActive({ mains: "mains_active" });
        const filteredMainsClasses = coursesArrayList?.filter((ele) => {
            if (ele?.courseType === "mains") {
                return ele;
            }
        })
        setFilteredClasses(filteredMainsClasses);
    }

    useEffect(() => {
        //Show Pre-Foundation classes on initial load
        preFoundationClasses();
    }, []);


    return (
        <>
            <ClassesHeroSection />
            <div className="courses_main">
                <div className="container">
                    <div className="heading">
                        <h2>All <span>Courses</span></h2>
                    </div>
                    <div className="row course_tab_main">
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="tab_content" onClick={() => preFoundationClasses()}>
                                <h3>Regular Courses</h3>
                                <p>Class 8 to 10</p>
                                <div className={active?.preFoundation}></div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="tab_content" onClick={() => foundationClasses()}>
                                <h3>Regular + Foundation Courses</h3>
                                <p>Class 8 to 10</p>
                                <div className={active?.foundation}></div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="tab_content" onClick={() => mainsClasses()}>
                                <h3>11<sup>th</sup> - 12 <sup>th</sup> & JEE/NEET/NDA</h3>
                                <p>Class 11 and 12 </p>
                                <div className={active?.mains}></div>
                            </div>
                        </div>
                    </div>

                    <ClassesCard filteredClasses={filteredClasses} />

                </div>
            </div>
        </>
    )
}

export default ClassesPage