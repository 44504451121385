import React from 'react'

const ContactUsHeroSection = () => {
    return (
        <main className="hero_section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 hero_section_text_main">
                        <div>
                            <h1>Contact Us</h1>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 hero_section_img">
                        <img src="/images/contact-us-img/hero-section-img.png" className="img-fluid" alt="hero section img" />
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ContactUsHeroSection