import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Multiselect from 'multiselect-react-dropdown';
import { courseArray } from "../../arrays/sign-up-page-array/courseArray";

const SignUp = () => {
    const navigate = useNavigate();
    const [course, setCourse] = useState([]);

    return (
        <div className="modal_section">
            <div className="centered_modal sign_up">
                <div className="modal_header">
                    <img className="img-fluid" src="/logo.png" alt="logo" />
                    <div className="modal_close_icon" onClick={() => navigate("/public/")}><i className="fa-solid fa-xmark"></i></div>
                </div>
                <div className="modal_body">
                    <div className="title">
                        <h3>Sign Up</h3>
                    </div>
                    <div className="input_group_main">
                        <div className="input_group">
                            <input type="text" name="studentName" className="form-control" autocomplete="off" required />
                            <label className="input_label">Enter Student Name</label>
                        </div>
                    </div>
                    <div className="input_group_main">
                        <div className="input_group">
                            <input type="text" name="schoolName" className="form-control" autocomplete="off" required />
                            <label className="input_label">Enter School Name</label>
                        </div>
                    </div>
                    <div className="input_group_main">
                        <div className="input_group">
                            <input type="text" name="address" className="form-control" autocomplete="off" required />
                            <label className="input_label">Enter Address</label>
                        </div>
                    </div>
                    <Multiselect
                        options={courseArray}
                        displayValue="courseName"
                        name="courseName"
                        showCheckbox={true}
                        onSelect={(event) => setCourse(event)}
                        onRemove={(event) => setCourse(event)}
                        placeholder="Select Course"
                        hidePlaceholder={true}
                        showArrow
                        closeIcon="cancel"
                        customArrow={<><i className="fa fa-angle-down"></i></>}
                        keepSearchTerm={true}
                    />
                    <select className="form-select">
                        <option value="">Select City</option>
                        <option value="">Nagpur</option>
                        <option value="">Wardha</option>
                        <option value="">Bhandara</option>
                    </select>
                    <div className="input_group_main">
                        <div className="input_group">
                            <input type="password" name="password" className="form-control" autocomplete="off" required />
                            <label className="input_label">Enter Password</label>
                        </div>
                    </div>
                    <div className="input_group_main">
                        <div className="input_group">
                            <input type="password" name="confirmPassword" className="form-control" autocomplete="off" required />
                            <label className="input_label">Confirm Password</label>
                        </div>
                    </div>
                </div>
                <div className="term_and_conditions">
                    <input class="form-check-input" type="checkbox"  />
                    <p>By signing up you agree to our <span>T&C</span> and <span>Privacy Policy</span>.</p>
                </div>
                <div className="modal_footer">
                    <button>Sign Up</button>
                </div>
                <p>Already have an account? <span onClick={() => navigate("/public/login")}>LogIn</span></p>
            </div>
        </div>
    );
}

export default SignUp;