import React from 'react'
import { useNavigate } from 'react-router-dom';

const InvestingCardSection = () => {

    const navigate = useNavigate();

    return (
        <div className="container">
            <div className="row investing_card_section">
                {/* <div className="col-12">
                    <h3>It's time to start investing in yourself in learning</h3>
                    <p>Match your goals to our programs, explore your options, and map out your path to success. <br /> I plan to use <span>Sollverz</span> for a long time!.</p>
                    <div className="card_button investing_card_button">
                        <button onClick={() => navigate("/public/classes")}>Get Started</button>
                        <div className="arrow_icon">
                            <i className="fa-solid fa-arrow-right" />
                        </div>
                    </div>
                    <div className="before"></div>
                    <div className="after"></div>
                </div> */}
            </div>
        </div>
    )
}

export default InvestingCardSection