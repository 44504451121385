export const achieveGoalsCardArray = [
    {
        img: "/images/home-img/New folder/light-img.png",
        // heading:"Learn the latest skills",
        alt: "light img",
        title: " Unlock Success with Cutting-Edge Skills for JEE, NEET, NDA, and Entrance Exams!",
        paragraph:"Elevate your exam preparation with our expertly crafted courses tailored to ace JEE, NEET, NDA, and other competitive tests.  Dive into the latest strategies, gain hands-on practice, and master essential skills with guidance from top instructors.Achieve your academic dreams and stand out from the competition with our dynamic and engaging resources.  Your path to success starts here—enroll now and conquer your exams with confidence!",
        bgColor: "#9A1E5E",
        color: "#9A1E5E",
        hoverColor: "#9A1E5E"
    },
    {
        img: "/images/home-img/New folder/home-img.png",
        alt: "home img",
        // heading:"Get ready for a career",
        title: "Ready for a Thriving Career!",
        paragraph: "Prepare yourself for a successful future with our comprehensive career-focused programs.  Whether you’re aiming to excel in engineering, medicine, or any other field, our expert-led courses and resources will equip you with the skills and knowledge needed to stand out in the job market. From personalized learning plans to hands-on experience, we provide the tools and support to help you launch your career with confidence. Start your journey today and turn your career aspirations into reality!",
        bgColor: "#9A1E5E",
        color: "#9A1E5E",
        hoverColor: "#9A1E5E"
    },
    // {
    //     img: "/images/home-img/New folder/award-img 1.png",
    //     alt: "certificate img",
    //     title: "Earn a certificate",
    //     color: "#9A1E5E",
    //     paragraph: "Earn industry-recognized certificates that validate your expertise and enhance your career prospects. Our programs provide practical skills and knowledge, preparing you to excel in your chosen field and achieve professional success.",
    //     bgColor: "#9A1E5E",
    //     hoverColor: "#9A1E5E"
    // }
]