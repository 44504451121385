export const userSampleVideoArray = [
    {
        courseType: "pre_foundation",
        class: [{
            className: "5th",
            videoUrl: ["/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v"],
        }, {
            className: "6th",
            videoUrl: ["/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v"],
        }, {
            className: "7th",
            videoUrl: ["/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v"]
        }],
        
    },
    {
        courseType: "foundation",
        class: [{
            className: "8th",
            videoUrl: ["/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v"],
        }, {
            className: "9th",
            videoUrl: ["/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v"],
        }, {
            className: "10th",
            videoUrl: ["/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v"]
        }],
    },
    {
        courseType: "higher_secondary",
        class: [{
            className: "11th",
            videoUrl: ["/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v"],
        }, {
            className: "12th",
            videoUrl: ["/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v"],
        }, {
            className: "JEE",
            videoUrl: ["/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v"]
        }, {
            className: "NEET",
            videoUrl: ["/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v"],
        }, {
            className: "NDA",
            videoUrl: ["/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v", "/video/user-video/Back Office And User Part 2.m4v"],
        }],
    },
]
