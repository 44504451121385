export const ReviewArray=[
    {
        img:"/images/review-img/card_1.png",
        alt:"Studernt-Review-one",
        title:"Rahul M",
        class:"10th Grade Student",
        content:"Empowering Learning Experiences with 3D Videos. The digital classrooms are engaging, and the 3D videos help me visualize complex concepts with ease.",
    },
    {
        img:"/images/review-img/card_2.png",
        alt:"Studernt-Review-Two",
        title:"Priya S",
        class:"9th Grade Student",
        content:"Personalized Attention and Growth - Sollverz makes me feel like my education is truly personalized and the teachers are always available to guide me. ",
    },
    {
        img:"/images/review-img/card_3.png",
        alt:"Studernt-Review-Three",
        title:"Arjun K",
        class:"11th Grade Student",
        content:"Attending Sollverz has boosted my confidence and skills. The combination of digital classrooms and interactive learning that makes me never my lesson. ",
    },

    {
        img:"/images/review-img/card_4.png",
        alt:"Studernt-Review-four",
        title:"Nisha P",
        class:"8th Grade Student",
        content:"Innovative Learning with Sollverz has changed the way I learn. The integration of technology in our daily lessons makes learning more interactive and fun.",
    }
]