import React from 'react'

const InquiryForm = () => {
    return (
        <div className="inquiry_form_main">
            <div className="inquiry_form">
                <h2>Inquiry Form</h2>
                <div className="input_group">
                    <input type="text" name="name" className="form-control" required />
                    <label className="input_label">Enter Name</label>
                </div>
                <div className="input_group">
                    <input type="text" name="email" className="form-control" required />
                    <label className="input_label">Enter E-mail</label>
                </div>
                <textarea className="form-control" placeholder="Enter Message..."></textarea>
                <button>Submit</button>
            </div>
        </div>
    )
}

export default InquiryForm