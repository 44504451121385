import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { userSampleVideoArray } from '../../../arrays/user-sample-video-array/userSampleVideoArray'

const VideoSection = () => {
    const [classes, setClasses] = useState();
    const [video, setVideo] = useState();

    //  filter classes by course type
    const filterCourses = () => {
        const courseData = userSampleVideoArray.filter((data) => data.courseType === "pre_foundation")
        setClasses(courseData[0]?.class);
        setVideo(courseData[0]?.class[0]?.videoUrl);
    }

    //  filter classes by course videos
    function cousreWiseVideo(e) {
        const videoList = classes.filter((data) => data?.className === e.target.value)
        setVideo(videoList[0]?.videoUrl);
    }

    useEffect(() => {
        filterCourses();
    }, []);

    return (
        <>
            <div className="row user_video_main">
                <div className="col-6">
                    <h2>Explore Concept Videos</h2>
                </div>

                <div className="col-6" >
                    <select onChange={(e) => { cousreWiseVideo(e) }}>
                        {classes?.map((classList, index) => {
                            return (
                                <option value={`${classList?.className}`} key={index} > {classList?.className}</option>
                            )
                        })}
                    </select>

                </div>

                <div className="container">
                    <div className="row">
                        {video?.map((video, index) => {
                            return (
                                <div key={index} className="col-md-4 col-lg-4">
                                    <video controls>
                                        <source src={video} />
                                    </video>
                                </div>
                            )
                        })}
                    </div>
                </div>

            </div>
        </>
    )
}

export default VideoSection