import React from 'react';
import { BlogArray } from "../../../arrays/blog-page-array/BlogArray";

const Blogs = () => {
    return (
        <>
            <main className="hero_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 hero_section_text_main">
                            <div>
                                <h1>Our Blogs</h1>
                                <p>Discover the latest trends, insights, and innovations in education with Sollverz. Stay informed, inspired, and ahead in your learning journey.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 hero_section_img">
                            <img src="/images/blogs-img/blogs_img.png" className="img-fluid" alt="hero section img" />
                        </div>
                    </div>
                </div>
            </main>

            <div className='blog_first_fold second_fold'>
                <div className='container'>
                    <div className='heading'>
                        <h2>Check Out Our Latest Blogs</h2>
                    </div>
                </div>

            </div>
            <div className='blog_second_fold'>
                <div className='container'>
                    <div className="row card_section">
                        {BlogArray?.map((items, index) => {
                            return (
                                <div className="col-lg-4 col-md-4" key={index}>
                                    <div className="card_blog">
                                        <div className="card_img_blog">
                                            <img className="img-fluid" src={items?.img} alt={items?.alt} />
                                        </div>
                                        <div className="card_title">
                                            <h3 className='blog_card_title'>{items?.title}</h3>
                                            <p className='blog_p'>{items?.content}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                    </div>


                </div>

            </div>

        </>
    )
}

export default Blogs