import React from "react";
import PublicHeader from "./common-components/PublicHeader";
import MainRoutes from "./routes/MainRoutes";
import PublicFooter from "./common-components/PublicFooter";

function App() {

  return (
    <div className="App">
      <PublicHeader />
      <MainRoutes />
      <PublicFooter />
    </div>
  );
}

export default App;
