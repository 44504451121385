export const mainsScholarshipStudentArrray = [
    {
        studentDetail: [{ img: "/images/scholarship-img/pre-foundation-availed-students-img/student3.png", alt: "student img", name: "John Doe", marks: 98 },
        { img: "/images/scholarship-img/pre-foundation-availed-students-img/student2.png", alt: "student img", name: "John Walter", marks: 95 },
        { img: "/images/scholarship-img/pre-foundation-availed-students-img/student1.png", alt: "student img", name: "James Jason", marks: 93 },
        { img: "/images/scholarship-img/pre-foundation-availed-students-img/student2.png", alt: "student img", name: "Rock Maxwell", marks: 90 }]
    },
    {
        studentDetail: [{ img: "/images/scholarship-img/pre-foundation-availed-students-img/student2.png", alt: "student img", name: "John Doe", marks: 88 },
        { img: "/images/scholarship-img/pre-foundation-availed-students-img/student1.png", alt: "student img", name: "John Walter", marks: 85 },
        { img: "/images/scholarship-img/pre-foundation-availed-students-img/student3.png", alt: "student img", name: "James Jason", marks: 84 },
        { img: "/images/scholarship-img/pre-foundation-availed-students-img/student2.png", alt: "student img", name: "David Malan", marks: 81 }]
    }
];