import React from 'react'
import { useNavigate } from 'react-router-dom';

const PublicFooter = () => {
    const navigate = useNavigate();

    return (
        <footer className="public_footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="company_info">
                            <img src="/Sollverz_logo.png" className="img-fluid" alt="logo" />
                            <p>We are committed to your success. Join us and embark on a journey toward adamic excellence and a bright 
                                future. Together, we  will build a solid foundation for your dreams of becoming a top engineer or doctor.</p>
                            <div className="social_media_links">
                                <div className="media_icon">
                                    <i className="fa-brands fa-facebook-f"></i>
                                </div>
                                <div className="media_icon">
                                    <i className="fa-brands fa-twitter"></i>
                                </div>
                                <div className="media_icon">
                                    <i className="fa-brands fa-linkedin-in"></i>
                                </div>
                                <div className="media_icon">
                                    <i className="fa-brands fa-google-plus-g"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 footer_contact_us">
                        <div className="title">
                            <h6>Contact Us</h6>
                        </div>
                        <div className="contact_us_detail mb-0">
                            <i className="fa-solid fa-location-dot"></i>
                            <p style={{color: "#fff"}}>Corporate Office<br/>
                            Shop No. 24 & 25 Shewalkar Arcade E3, LaxmiBhavan Square, WHC Raod, Dharampeth  Nagpur - 440022(MS).</p>
                        </div>
                        <div className="contact_us_detail mt-0 mb-0">
                            <i className="fa-solid fa-location-dot"></i>
                            <p style={{color: "#fff"}}>Academy <br/>
                            Level G 1 & 2 Admark Junction,275 LaxmiNagar,Nr Rani Laxmi Sabhagruh,  Nagpur - 440022(MS).</p>
                        </div>
                        <div className="contact_us_detail">
                            <i className="fa-solid fa-envelope"></i>
                            <p style={{color: "#fff"}}><a href="mailto:info@Sollverz.com">info@Sollverz.com</a></p> &nbsp;&nbsp;
                            <i className="fa-solid fa-phone"></i>
                            <p style={{color: "#fff"}}><a href="tel:1800 88 95 212">Toll Free:1800 88 95 212</a></p>
                        </div>
                        <div className="contact_us_detail">
                        <i class="fa-solid fa-mobile"></i>
                            <p style={{color: "#fff"}}><a href="tel:+91 99226071650">99226071650 </a>/ <a href="tel:+91 9226071642">9226071642</a></p><br/>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-12 mt-lg-0 mt-md-4 footer_company_pages">
                        <div className="title">
                            <h6>Company</h6>
                        </div>
                        <ul style={{color: "#fff"}}>
                            <li onClick={() => navigate("/about-us")}>About Us</li>
                            <li onClick={() => navigate("/courses")}>Courses</li>
                            <li onClick={() => navigate("/review")}>Student Reviews</li>
                            <li onClick={() => navigate("/review")}>Parent Reviews</li>
                            <li onClick={() => navigate("/blog")}>Blogs</li>
                            <li onClick={() => navigate("/news")}>News</li>
                            <li onClick={() => navigate("/contact-us")}>Support</li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 mt-lg-0 mt-md-4">
                        <div className="title">
                            <h6>Features</h6>
                        </div>
                        <ul style={{color: "#fff"}}>
                            <li>Video Classes</li>
                            <li>Live Classes</li>
                            <li>Test Series</li>
                            <li>24/7 Assistance</li>
                            <li>Expert Faculties</li>
                            <li>Learning App</li>
                            <li>State Of The Art Digital  Classrooms</li>
                            <li>Online and Offline Teaching</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer_bottom_section">
                <p>Copyright 2022 All Rights Reserved, Designed and Developed by All Indian IT Services.</p>
            </div>
        </footer>
    )
}

export default PublicFooter