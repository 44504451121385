import React, { useEffect, useState } from 'react';
import { userScholershipSectionArray } from '../../../arrays/user-scholership-section-array/userScholershipSectionArray';

const UserScholershipTestCard = () => {
    const [scholershipExam, setScholershipExam] = useState();

    const filterExam = () => {
        const examData = userScholershipSectionArray.filter((data) => data.courseType === "pre_foundation")
        console.log(examData)
        setScholershipExam(examData)
    }

    useEffect(() => {
        filterExam();
    }, []);

    return (
        <div className="scholarship_test_card_main">
            {scholershipExam?.map((items, index) => {
                return (
                    <div className={`row scholarship_test_card ${items?.testType === "Foundation Test" ? "flex-row-reverse" : ""}`} style={{ backgroundImage: `url(${items?.bgImage})` }} key={index}>

                        <div className="col-lg-6 col-md-6 card_image" style={{ justifyContent: `${items?.testType === "Foundation Test" ? "end" : "start"}` }}>
                            <img src={items?.img} alt={items?.alt} className="img-fluid" />
                        </div>

                        <div className="col-lg-6 col-md-6 card_content" style={{ paddingLeft: `${items?.testType === "Foundation Test" ? "10%" : "0"}` }}>
                            <div>
                                <div className="course_type">
                                    <p>{items?.testType}</p>
                                </div>
                                <h6>{items?.classes}</h6>
                                <h3>{items?.text}</h3>
                                <div className="card_button test_card_button">
                                    <button>Join Now</button>
                                    <div className="arrow_icon">
                                        <i className="fa-solid fa-arrow-right" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                );
            })}
        </div>
    )
}

export default UserScholershipTestCard