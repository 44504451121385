import React from 'react'

const AchieveGoalsSection = () => {
    return (
        <>
            <div className="row achieve_goal_main">

                <div className="col-md-6 col-lg-6 achieve_content">
                    <div>
                        <h2>Achieve Your Goals with <span>Sollverz</span> </h2>
                        <h3>Get the plan and start learning</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                            has been the industry's standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                </div>

                <div className="col-md-6 col-lg-6 achieve_main">
                    <div className="achieve_icon_div">

                        <div className='big_icon_div '>

                            <div className='icon_div'>
                                <img src="/images/home-img/light-img.png" alt="" />
                            </div>
                            <h4>Learn the latest <br /> skills</h4>

                        </div>

                        <div className='big_icon_div'>

                            <div className='icon_div'>
                                <img src="/images/home-img/home-img.png" alt="" />
                            </div>
                            <h4>Get ready for a <br /> career</h4>

                        </div>

                        <div className='big_icon_div'>

                            <div className='icon_div'>
                                <img src="/images/home-img/award-img.png" alt="" />
                            </div>
                            <h4>Earn a certificate</h4>

                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default AchieveGoalsSection