import React from 'react'

const ContactInformationCard = () => {
    return (
        <div className="contact_information_card">
            <h2>Contact Information</h2>
            {/* <p>Fill up the form and our Team will get <br /> back to you</p> */}
            <div className="contact_us_detail_main">
                <div className="contact_us_detail">
                    <i className="fa-solid fa-location-dot"></i>
                    <p> <b>Corporate Office</b><br/>
                    Shop No. 24 & 25 Shewalkar Arcade E3, LaxmiBhavan Square, WHC Raod, Dharampeth  Nagpur - 440022(MS).<br/><br/>
                    <b>Academy</b> <br/>
                    Level G 1 & 2 Admark Junction,275 LaxmiNagar,Nr Rani Laxmi Sabhagruh,  Nagpur - 440022(MS).</p>
                </div>
                
                <div className="contact_us_detail">
                    <i className="fa-solid fa-envelope"></i>
                    <p>info@Sollverz.com</p>
                </div>
                <div className="contact_us_detail">
                    <i className="fa-solid fa-phone"></i>
                    <p>99226071650 / 9226071642</p>
                </div>
                <div className="contact_us_detail">
                    <i className="fa-solid fa-phone"></i>
                    <p>Toll Free:1800 88 95 212 </p>
                </div>
            </div>
        </div>
    )
}

export default ContactInformationCard