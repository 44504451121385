import React from 'react'
import ContactInformationCard from './ContactInformationCard'
import ContactUsHeroSection from './ContactUsHeroSection'
import InquiryForm from './InquiryForm'
import NewsLetter from './NewsLetter'

const ContactUsPage = () => {
    return (
        <>
            <ContactUsHeroSection />
            <div className="contact_section">
                <div className="container">
                    <div className="row contact_main">
                        <div className="col-lg-6 col-md-6">
                            <ContactInformationCard />
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <InquiryForm />
                        </div>
                    </div>
                    <div className="row location_section">
                        {/* <div className="col-12 p-0">
                            <iframe title="Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d37512.43499927702!2d79.03001004613797!3d21.124318971634896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c07e4d31ee15%3A0x6b2d0d757d943a70!2sAll%20Indian%20IT%20Services%2C%20Nagpur!5e0!3m2!1sen!2sin!4v1670320937388!5m2!1sen!2sin" style={{ border: 0, loading: "lazy", referrerpolicy: "no-referrer-when-downgrade" }} />
                        </div> */}
                    </div>
                    <NewsLetter />
                </div>
            </div>

        </>
    )
}

export default ContactUsPage