import React from 'react'

function HeroSection() {
    return (
        <>
            <div className="row user_greeting">

                <div className="col-6 user_login_data">
                    <div>
                        <h2>Welcome, <span>User</span> </h2>
                        <h3>Everthing is here to learn!</h3>
                    </div>
                </div>

                <div className="col-6 trial_count">
                    <h2>Trial 47:59 Left</h2>
                </div>

            </div>
            <div className="row user_greeting_description" >

                <div className="col-md-6 col-lg-6 description" >
                        <img src="/images/scholarship-img/pre-foundation-card-img.png" alt="Grow yourself" />
                </div>

                <div className="col-md-6 col-lg-6 description" >
                    <div>
                        <h2>Grow yourself with <br /> <span>Sollverz</span> </h2>
                        <h3>Learn from the Best Tutors</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                            has been the industry's standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type and scrambled it to make a type specimen book</p>
                    </div>
                </div>

            </div>
        </>
    )
}

export default HeroSection;