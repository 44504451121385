export const pricingCardXSTDArray = [
    {
        courseType: "Regular Batch for 10th STD",
        price:56000,
        featureList: ["Video Classes", "Expert Faculties", "Learning App", "Test Series"],
    },
    {
        courseType: "Regular + Foundation for 10th STD",
        price:105000,
        featureList: ["Video Classes", "Expert Faculties", "Learning App", "Test Series"],
    },
    {
        courseType: "Foundation for 10th STD",
        price:42000,
        featureList: ["Video Classes", "Expert Faculties", "Learning App", "Test Series"],
    },
]