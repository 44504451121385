import React from 'react'

const ScholarshipHeroSection = () => {
    return (
        <main className="hero_section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 hero_section_text_main">
                        <div>
                            <h1>Paying for Classes made easy <br /> with <span>Sollverz</span></h1>
                            <h6>Sollverz Scholarship plan for every bright student</h6>
                            <p>Paying for classes made easy with Sollverz.
                            Sollverz offers a scholarship plan for every bright student.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 hero_section_img">
                        <img src="/images/pricing-img/hero-section-img.png" className="img-fluid" alt="hero section img" />
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ScholarshipHeroSection