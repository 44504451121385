import React from 'react'

const PrisingHeroSection = () => {
    return (
        <main className="hero_section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 hero_section_text_main">
                        <div data-aos="zoom-in-down" data-aos-duration="2000">
                            <h1>Choose The Plan<br />That's Right For You</h1>
                            <p>Choose the plan that suits you best.
                            Find the perfect fit for your learning needs.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 hero_section_img">
                        <img src="/images/pricing-img/hero-section-img.png" className="img-fluid" alt="hero section img" />
                    </div>
                </div>
            </div>
        </main>
    )
}

export default PrisingHeroSection