export const courseArray = [
    {
        courseName: "5th"
    },
    {
        courseName: "6th"
    },
    {
        courseName: "7th"
    },
    {
        courseName: "8th"
    },
    {
        courseName: "9th"
    },
    {
        courseName: "10th"
    },
    {
        courseName: "11th"
    },
    {
        courseName: "12th"
    },
    {
        courseName: "JEE"
    },
    {
        courseName: "NEET"
    },
    {
        courseName: "NDA"
    },
];