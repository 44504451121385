import React from 'react';
import {learnerCounterArray} from "../../../arrays/home-page-array/LearnerCounterArray";

const LearnerCounter = () => {
    return (
        <div className="learner_counter_main">
            {learnerCounterArray?.map((items, index) => {
                return (
                    <div className="learner_counter_card" key={index}>
                        <div className="icon" style={{ backgroundColor: items?.bgColor }}>
                            <img src={items?.img} alt={items?.alt} />
                        </div>
                        <div>
                            <h3>{items?.count}<sup>+</sup></h3>
                            <p>{items?.text}</p>
                        </div>
                    </div>
                );
            })}

        </div>
    )
}

export default LearnerCounter