import React from 'react'
import HeroSection from '../../user/home/HeroSection'
import AchieveGoalsSection from './AchieveGoalsSection'
import ExploreCourse from './ExploreCourse'
import UserScholershipTestCard from './UserScholershipTestCard'
import VideoSection from './VideoSection'

const HomePage = () => {
    return (
        <>
            <div className="container">
                <HeroSection />
                <AchieveGoalsSection />
                <UserScholershipTestCard />
                <VideoSection />
                <ExploreCourse />

            </div>
        </>
    )
}

export default HomePage