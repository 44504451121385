import React from 'react';
import { useNavigate } from 'react-router-dom';
import { pricingCardXSTDArray } from '../../../arrays/pricing-page-array/pricingCardXSTDArray';

const PricingCardXStd = () => {
    const navigate = useNavigate();
    
    return (
        <div className="row pricing_card_section ">
             <div className="heading mb-3">
                        <h2>For 10th STD<span>Regular + Foundation </span></h2>
                    </div>
            {pricingCardXSTDArray?.map((items, index) => {
                return (
                    <>
                    <div className="col-lg-4 col-md-4 pricing_card_main" key={index}>
                        <div className="pricing_card">
                            <div className="card">
                                <div className="card_header">
                                    <h6>Pricing Plan For</h6>
                                    <h6>{items?.courseType}</h6>
                                    <h6><i className="fa-solid fa-indian-rupee-sign"></i>{items?.price}</h6>
                                </div>
                                <div className="feature_list">
                                    {items?.featureList?.map((list, index) => {
                                        return (
                                            <div className="list_item" key={index}>
                                                <i className="fa-solid fa-check"></i>
                                                <p>{list}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="card_button pricing_card_button" style={{marginLeft: "50px" }}>
                                    <button onClick={() => navigate("/public/contact-us")}>Enquire Now</button>
                                    <div className="arrow_icon">
                                        <i className="fa-solid fa-arrow-right" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    </>
                );
            })}

        </div>
    )
}

export default PricingCardXStd