export const scholarshipTestCardArray = [
    {
        img: "/images/scholarship-img/pre-foundation-card-img.png",
        alt: "pre-foundation card img",
        bgImage: "/images/scholarship-img/pre-foundation-bg.png",
        testType: "Pre Foundation Test",
        classes: "For Class 5 to 7",
        text: "Join the test and get Scholarship up to 40%"
    },
    {
        img: "/images/scholarship-img/foundation-card-img.png",
        alt: "pre-foundation card img",
        bgImage: "/images/scholarship-img/foundation-bg.png",
        testType: "Foundation Test",
        classes: "For Class 8 to 10",
        text: "Join the test and get Scholarship up to 40%"
    },
    {
        img: "/images/scholarship-img/mains-card-img.png",
        alt: "pre-foundation card img",
        bgImage: "/images/scholarship-img/mains-bg.png",
        testType: "11th - 12th & JEE/NEET/NDA",
        classes: "For Class 11th - 12th & JEE/NEET/NDA",
        text: "Join the test and get Scholarship up to 40%"
    }
];