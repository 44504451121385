import React from 'react'
import { Routes, Route } from 'react-router-dom';
import HomePage from '../pages/user/home/HomePage';

const UserRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<HomePage />} />
            </Routes>
        </>
    )
}

export default UserRoutes


