import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PublicRoutes from "./PublicRoutes";
import UserRoutes from "./UserRoutes";

const MainRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/*" element={<PublicRoutes />}></Route>
                <Route path="/user/*" element={<UserRoutes />}></Route>
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </>
    );
}

export default MainRoutes;
