import React from 'react'

const ApplicationPlatformSection = () => {
    return (
        <div className="application_platform_section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 content">
                        <h2><span>Sollverz</span> app is available on <br /> all platforms</h2>
                        <div className="platform_icon_main">
                            <div className="platform_icon">
                                <i className="fa-solid fa-laptop"></i>
                                <div className="title">
                                    <p>Laptop</p>
                                </div>
                            </div>
                            <div className="platform_icon">
                                <i className="fa-solid fa-tablet"></i>
                                <div className="title">
                                    <p>Tablet</p>
                                </div>
                            </div>
                            <div className="platform_icon">
                                <i className="fa-solid fa-mobile"></i>
                                <div className="title">
                                    <p>Mobile</p>
                                </div>
                            </div>
                        </div>
                        <div className="app_links">
                            <img src="/images/home-img/play-store-img.png" alt="play store img" />
                            <img src="/images/home-img/app-store-img.png" alt="app store img" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 platform_image">
                        <img src="/images/home-img/tablet-img.png" alt="tablet img" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicationPlatformSection