import React from 'react';
import { useNavigate } from 'react-router-dom';
import { howItsWorkCardArray } from '../../../arrays/home-page-array/howItWorksCardArray';

const HowItWorksSection = () => {
    const navigate = useNavigate();

    //Redirect to page on Button Click
    const redirectOnUrl = (buttonName) => {

        switch (buttonName) {
            case "Step 1":
                if (buttonName === "Step 1") {
                    navigate("/public/mobile-verification");
                }
                break;
            case "Step 2":
                if (buttonName === "Step 2") {
                    navigate("/public/scholarship");
                }
                break;
            case "Step 3":
                if (buttonName === "Step 3") {
                    navigate("/public/classes");
                }
                break;
            case "Step 4":
                if (buttonName === "Step 4") {
                    navigate("/public/mobile-verification");
                }
                break;
        }
    }

    return (
        <div className="howItWorks_section">
            <div className="container">
                <div className="row content">
                    <div className="col-lg-6 col-md-6">
                        <h2>Why Choose Us -</h2>
                        <h1>Sollverz Academic</h1>
                        {/* <h2>How it works ?</h2> */}
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <h6 style={{color: "#E33991"}}>"Empowering Futures, One Student at a Time"</h6>
                        <p style={{textAlign: "Justify"}}>At Sollverz Academy, we’re dedicated to transforming lives
                             through education. Our top-notch resources, expert guidance, and nurturing environment 
                             empower students to reach their ultimate potential. For parents, our commitment means peace 
                             of mind knowing your child is receiving unparalleled support and preparation for a successful 
                             future. Whether your goal is to foster the next generation of innovative engineers or 
                             pioneering doctors, we provide the tools and inspiration to make it happen. Join us and
                              watch your child’s academic excellence and future brilliance unfold!</p>
                    </div>
                </div>

                <div className="row card_section">
                    {howItsWorkCardArray?.map((items, index) => {
                        return (
                            <div className="col-lg-4 col-md-4 card_main" key={index}>
                                <div className="card" style={{border: `1px solid ${items?.border} !important` }}>
                                    <div className="card_img">
                                        <img src={items?.img} alt={items?.alt} />
                                        {/* <div className="count">
                                            <h6>{items?.count}</h6> 
                                        </div> */}
                                    </div>
                                    <div className="card_title">
                                        <h3>{items?.title}</h3>
                                        <p>
                                            {items?.paragraph}
                                        </p>
                                    </div>
                                    {/* <div className="card_button">
                                        <button onClick={() => redirectOnUrl(items?.buttonText)}>{items?.buttonText}</button>
                                        <div className="arrow_icon">
                                            <i className="fa-solid fa-arrow-right" />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        );
                    })}

                </div>
            </div>
        </div>
    )
}

export default HowItWorksSection