export const TwoYearsProgrammeArray = [
    {
        courseType: "JEE",
        duration: "2 Years",
        price: 320000,
        featureList: ["Video Classes", "Expert Faculties", "Learning App", "Test Series"],
    },
    {
        courseType: "NEET",
        duration: "2 Years",
        price: 320000,
        featureList: ["Video Classes", "Expert Faculties", "Learning App", "Test Series"],
    },
    {
        courseType: "NDA",
        duration: "2 Years",
        price: 320000,
        featureList: ["Video Classes", "Expert Faculties", "Learning App", "Test Series"],
    },
    {
        courseType: "MHT-CET",
        duration: "2 Years",
        price: 320000,
        featureList: ["Video Classes", "Expert Faculties", "Learning App", "Test Series"],
    },
]