export const coursesArrayList = [
    {
        img: "/images/classes-img/sci1.jpg",
        alt: "Course one img",
        courseName: "Science",
        class:"8th STD",
        courseType: "pre-foundation",
        lessons: 20,
        quiz: 4,
        students: 200,
        content: "Introduction to basic concepts in Physics, Chemistry, and Biology. Focus on building a strong foundation in fundamental principles.",

    },
    {
        img: "/images/classes-img/pre-foundation-course2.png",
        alt: "Course one img",
        courseName: "Mathematics",
        class:"8th STD",
        courseType: "pre-foundation",
        lessons: 30,
        quiz: 7,
        students: 250,
        content: "Basic arithmetic, fractions, decimals, integers, introduction to algebra and geometry",

    },
    {
        img: "/images/classes-img/pre-foundation-course3.png",
        alt: "Course one img",
        courseName: "Preparation Focus",
        class:"8th STD",
        courseType: "pre-foundation",
        // lessons: 25,
        quiz: 6,
        students: 300,
        content: "Developing problem-solving skills, logical reasoning, and analytical thinking. Encouraging a curiosity-driven approach to science and math",

    },
    {
        img: "/images/classes-img/foundation-course2.png",
        alt: "Course one img",
        courseName: "Science",
        courseType: "pre-foundation",
        class:"9th STD",
        lessons: 20,
        quiz: 5,
        students: 300,
        content: "Detailed study of Physics, Chemistry, and Biology concepts, with an emphasis on understanding scientific principles and their applications.",

    },
    {
        img: "/images/classes-img/math2.jpg",
        alt: "Course one img",
        courseName: "Mathematics",
        class:"9th STD",
        courseType: "pre-foundation",
        lessons: 20,
        quiz: 5,
        students: 300,
        content: "Algebraic expressions, linear equations, geometry, trigonometry basics, and introductory calculus",
    },
    {
        img: "/images/classes-img/preparation.jpg",
        alt: "Course one img",
        courseName: "Preparation Focus",
        class:"9th STD",
        courseType: "pre-foundation",
        lessons: 20,
        quiz: 5,
        students: 300,
        content: "Strengthening foundational knowledge essential for JEE and NEET. Introduction to more complex problem-solving techniques and numerical reasoning.",
    },
    {
        img: "/images/classes-img/foundation-course1.png",
        alt: "Course one img",
        courseName: "Science",
        courseType:"pre-foundation",
        class:"10th STD",
        lessons: 20,
        quiz: 5,
        students: 300,
        content: "In-depth coverage of Physics, Chemistry, and Biology topics, including practical applications and experiments.",
    },
    {
        img: "/images/classes-img/math3.jpg",
        alt: "Course one img",
        courseName: "Mathematics",
        courseType: "pre-foundation",
        class:"10th STD",
        lessons: 20,
        quiz: 5,
        students: 300,
        content: "Advanced algebra, coordinate geometry, trigonometry, statistics, and calculus basics.",
    },
    {
        img: "/images/classes-img/pre-foundation-course1.png",
        alt: "Course one img",
        courseName: "Preparation Focus",
        class:"10th STD",
        courseType: "pre-foundation",
        // lessons: 20,
        quiz: 5,
        students: 300,
        content: "Strengthening foundational knowledge essential for JEE and NEET. Introduction to more complex problem-solving techniques and numerical reasoning.",
    },
    {
        img: "/images/classes-img/sSt.jpg",
        alt: "Course one img",
        courseName: "Overall Goals",
        // class:"Conceptual Clarity",
        courseType: "pre-foundation",
        // lessons: 20,
        // quiz: 5,
        // students: 300,
        content: ": Ensuring students understand the underlying principles rather than just memorizing facts.Foundation courses for JEE (Joint Entrance Examination) and NEET (National Eligibility cum Entrance Test) for classes 8th, 9th, and 10th typically encompass a comprehensive approach to subjects like Science, Mathematics, and Social Sciences (SST: Social Studies).",
    },
    {
        img: "/images/classes-img/foundation-course2.png",
        alt: "Course one img",
        courseName: "Science",
        courseType: "foundation",
        class:"8th STD",
        lessons: 20,
        quiz: 5,
        students: 300,
        content: "Introduction to basic concepts in Physics, Chemistry, and Biology.Emphasis on understanding natural phenomena and scientific principles.Experiments and demonstrations to foster curiosity and practical understanding."
    },
    {
        img: "/images/classes-img/pre-foundation-course3.png",
        alt: "Course two img",
        courseName: "Mathematics",
        courseType: "foundation",
        class:"8th STD",
        lessons: 25,
        quiz: 6,
        students: 300,
        content: "Fundamental concepts such as arithmetic operations, fractions, decimals, and basic algebra.Introduction to geometrical concepts and their applications.",

    },
    {
        img: "/images/classes-img/Social.jpg",
        alt: "Course three img",
        courseName: "Social Sciences (SST)",
        class:"8th STD",
        courseType: "foundation",
        lessons: 35,
        quiz: 5,
        students: 200,
        content: "Overview of subjects like History, Geography, Civics, and Economics.Focus on building foundational knowledge and understanding of historical events, geographical locations, political systems, and economic principles.",

    },
    {
        img: "/images/classes-img/sci1.jpg",
        alt: "Course four img",
        courseName: "Science",
        courseType: "foundation",
        class:"9th STD",
        lessons: 40,
        quiz: 6,
        students: 300,
        content: "Detailed study of Physics, Chemistry, and Biology.Exploration of more complex scientific theories and their practical applications.Hands-on experiments to reinforce theoretical concepts.",

    },
    {
        img: "/images/classes-img/math3.jpg",
        alt: "Course five img",
        courseName: "Mathematics",
        courseType: "foundation",
        class:"9th STD",
        lessons: 20,
        quiz: 5,
        students: 300,
        content: "Advanced algebra, including linear equations, quadratic equations, and simultaneous equations.Introduction to trigonometry, coordinate geometry, and basic calculus.",  

    },
    {
        img: "/images/classes-img/pre-foundation-course1.png",
        alt: "Course six img",
        courseName: "Social Sciences (SST)",
        courseType: "foundation",
        class:"9th STD",
        lessons: 20,
        quiz: 5,
        students: 300,
        content: "In-depth exploration of historical events, geographical regions, political ideologies, and economic systems.Analytical thinking encouraged through case studies and research projects.",
    },
    {
        img: "/images/classes-img/sci2.avif",
        alt: "Course seven img",
        courseName: "Science",
        courseType: "foundation",
        class:"10th STD",
        lessons: 20,
        quiz: 5,
        students: 300,
        content: "Comprehensive coverage of Physics, Chemistry, and Biology.Preparation for competitive exams with a focus on solving numerical problems and understanding theoretical concepts deeply.",
    },
    {
        img: "/images/classes-img/math2.jpg",
        alt: "Course eight img",
        courseName: "Mathematics",
        courseType: "foundation",
        class:"10th STD",
        lessons: 20,
        quiz: 5,
        students: 300,
        content: "MathematicsAdvanced topics in algebra, geometry, trigonometry, statistics, and calculus.Problem-solving techniques and application-oriented learning.",
    },
    {
        img: "/images/classes-img/sSt.jpg",
        alt: "Course nine img",
        courseName: "Social Sciences (SST)",
        courseType: "foundation",
        class:"10th STD",
        lessons: 20,
        quiz: 5,
        students: 300,
        content: "Detailed study of global history, environmental studies, political science, and economics.Emphasis on critical analysis, evaluating historical evidence, and understanding contemporary issues.",
    },
    {
        img: "/images/classes-img/pre-foundation-course3.png",
        alt: "Course ten img",
        courseName: "Overall Goals:",
        courseType: "foundation",
        // class:"11th STD",
        // lessons: 20,
        // quiz: 5,
        // students: 300,
        content: "Conceptual Understanding: Develop a strong foundation in Science, Mathematics, and Social Sciences to prepare for higher-level studies and competitive exams.Integration of Subjects: Encourage interdisciplinary learning where concepts from Science, Mathematics, and Social Sciences complement and reinforce each other.",
    },
    {
        img: "/images/classes-img/JEE.webp",
        alt: "Course one img",
        courseName: "Exam Preparation",
        courseType: "mains",
        lessons: 40,
        quiz: 6,
        students: 300,
        content: "Provide structured guidance and practice to excel in exams like JEE and NEET, emphasizing both theoretical knowledge and practical problem-solving skills.",

    },
    // {
    //     img: "/images/classes-img/pre-foundation-course3.png",
    //     alt: "Course two img",
    //     courseName: "Science",
    //     courseType: "mains",
    //     lessons: 25,
    //     quiz: 6,
    //     students: 300,
    //     content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever",

    // },
    // {
    //     img: "/images/classes-img/foundation-course1.png",
    //     alt: "Course three img",
    //     courseName: "Chemistry",
    //     courseType: "mains",
    //     lessons: 35,
    //     quiz: 5,
    //     students: 200,
    //     content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever",

    // },
    // {
    //     img: "/images/classes-img/foundation-course2.png",
    //     alt: "Course four img",
    //     courseName: "Biology",
    //     courseType: "mains",
    //     lessons: 20,
    //     quiz: 5,
    //     students: 300,
    //     content: "Developing problem-solving skills, logical reasoning, and analytical thinking. Encouraging a curiosity-driven approach to science and math",

    // }
];