import React from 'react';
import AvailedScholarshipStudentsCard from './AvailedScholarshipStudentsCard';
import ScholarshipHeroSection from './ScholarshipHeroSection';
import ScholarshipTestCard from './ScholarshipTestCard';
import { preFoundationScholarshipStudentArray } from '../../../arrays/scholarship-page-array/preFoundationScholarshipStudentArray';
import { foundationScholarshipStudentArray } from '../../../arrays/scholarship-page-array/foundationScholarshipStudentArray';
import { mainsScholarshipStudentArrray } from '../../../arrays/scholarship-page-array/mainsScholarshipStudentArray';

const ScholarshipPage = () => {

    return (
        <>
            <ScholarshipHeroSection />
            <div className="scholarship_main">
                <div className="container">
                    <div className="heading">
                        <h2><span>Sollverz</span> Scholarship Test</h2>
                    </div>
                    <ScholarshipTestCard />
                    <div className="availed_scholarship_section">
                        <div className="heading">
                            <h2><span>Sollverz</span> Students Made Us Proud</h2>
                        </div>
                        <div className="availed_secondary_heading">
                            <h3>Top Pre Foundation Scholarship Availed Students</h3>
                        </div>
                        <AvailedScholarshipStudentsCard type="pre-foundation" availedScholarshipStudents={preFoundationScholarshipStudentArray} />
                    </div>
                    <div className="availed_scholarship_section">
                        <div className="availed_secondary_heading">
                            <h3>Top Foundation Scholarship Availed Students</h3>
                        </div>
                        <AvailedScholarshipStudentsCard type="foundation" availedScholarshipStudents={foundationScholarshipStudentArray} />
                    </div>
                    <div className="availed_scholarship_section">
                        <div className="availed_secondary_heading">
                            <h3>Top JEE/NEET/NDA Scholarship Availed Students</h3>
                        </div>
                        <AvailedScholarshipStudentsCard type="mains" availedScholarshipStudents={mainsScholarshipStudentArrray} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ScholarshipPage