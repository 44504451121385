import React from 'react';
import { useNavigate } from 'react-router-dom';

const CockpitBatch = () => {
    const navigate = useNavigate();

    return (
        <div className="get_scholarship_card_main">
            <h3>COCK PIT BATCH</h3>
            <h3>25000 per month only Student Batch</h3>
            <div className="card_button get_scholarship_card_button">
                <button onClick={() => navigate("/public/scholarship")}>Join Now</button>
                <div className="arrow_icon">
                    <i className="fa-solid fa-arrow-right" />
                </div>
            </div>
            <div className="before_effect"></div>
            <div className="after_effect"></div>
        </div>
    )
}

export default CockpitBatch