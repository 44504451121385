
export const NewsArray = [
    {
        img: "/images/news-img/news_1.png",
        alt: "News-1",
        title: "News One",
        content: "Lorem Ipsum is simply dummy text of the printi ng and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.",

    },

    {
        img: "/images/news-img/news_2.png",
        alt: "News-2",
        title: "News Two",
        content: "Lorem Ipsum is simply dummy text of the printi ng and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.",

    },
    {
        img: "/images/news-img/news_3.png",
        alt: "News-3",
        title: "News Three",
        content: "Lorem Ipsum is simply dummy text of the printi ng and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.",

    },
    {
        img: "/images/news-img/news_3.png",
        alt: "News-4",
        title: "News Four",
        content: "Lorem Ipsum is simply dummy text of the printi ng and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.",

    },
    {
        img: "/images/news-img/news_1.png",
        alt: "News-5",
        title: "News Five",
        content: "Lorem Ipsum is simply dummy text of the printi ng and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.",

    },
    {
        img: "/images/news-img/news_2.png",
        alt: "New-6",
        title: "News Six",
        content: "Lorem Ipsum is simply dummy text of the printi ng and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.",

    },
];