export const learnerCounterArray = [
    {
        img: "/images/home-img/learner-counter-img.png",
        alt: "learner counter img",
        count: 2000,
        text: "Learners Counting",
        bgColor: "#FBD1D5"
    },
    {
        img: "/images/home-img/total-courses-img.png",
        alt: "total courses img",
        count: 100,
        text: "Total Courses",
        bgColor: "#A7FBE4"
    },
    {
        img: "/images/home-img/successful-students-img.png",
        alt: "successful students img",
        count: 1000,
        text: "Successful Students",
        bgColor: "#FBE8A1"
    }
]