import React from 'react';
import { useNavigate } from 'react-router-dom';

const TrialCard = () => {
    const navigate = useNavigate();

    return (
        <div className="pricing_trial_card_main">
            {/* <div className="pricing_trial_card">
                <h3>Register for our Free<br />2 - Days Trial now!</h3>
                <div className="card_button trial_card_button">
                    <button onClick={() => navigate("/public/mobile-verification")}>Register Now</button>
                    <div className="arrow_icon">
                        <i className="fa-solid fa-arrow-right" />
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default TrialCard