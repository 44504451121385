export const PricingCardXISTDArray = [
    {
        courseType: "Regular Batch for 9th STD",
        price: 50000,
        featureList: ["Video Classes", "Expert Faculties", "Learning App", "Test Series"],
    },
    {
        courseType: "Regular + Foundation for 9th STD",
        price:90000,
        featureList: ["Video Classes", "Expert Faculties", "Learning App", "Test Series"],
    },
    {
        courseType: "Foundation for 9th STD",
        price:35000,
        featureList: ["Video Classes", "Expert Faculties", "Learning App", "Test Series"],
    },
]