
export const BlogArray = [
    {
        img: "/images/blogs-img/blog_4.jpg",
        alt: "Blog1_images",
        title: "Harnessing VR in Classrooms: Revolutionizing Student Engagement",
        content: "Explore how virtual reality is transforming traditional learning environments, making education more immersive, interactive, and effective for students of all ages.",

    },

    {
        img: "/images/blogs-img/blog_5.jpg",
        title: "The Future of Digital Classrooms: Beyond the Screen",
        alt: "Blog2_images",
        content: "A look at the next wave of digital classroom technologies, enhancing collaboration, and how Sollverz is leading the way in innovative teaching methods.",

    },
    {
        img: "/images/blogs-img/blog_6.jpg",
        title: "Student-Parent Portals: Bridging the Communication Gap",
        alt: "Blog3_images",
        content: "Sollverz student-parent portal strengthens the home-school connection, providing real-time updates on academic progress and fostering a supportive learning environment.",

    },
    {
        img: "/images/blogs-img/blog_7.jpg",
        title: "Interactive 3D Videos: Making Learning Stick",
        alt: "Blog4_images",
        content: "Discover the power of 3D videos in enhancing memory retention and understanding, helping students grasp complex concepts with ease.",

    },
    {
        img: "/images/blogs-img/blog_8.jpg",
        title: "Biometric Attendance: Enhancing Student Accountability",
        alt: "Blog5_images",
        content: "Understand the benefits of biometric attendance systems in schools, ensuring accurate records and promoting student responsibility and punctuality.",

    },
    {
        img: "/images/blogs-img/blog_4.jpg",
        title: "The Rise of Personalized Learning: Tailoring Education to Individuals",
        alt: "Blog6_images",
        content: "Explore how Sollverz is embracing personalized learning approaches, catering to the unique needs and strengths of each student for optimal academic growth.",

    },
    
];