import React from 'react'
import { useNavigate } from 'react-router-dom';

function LogIn() {
    const navigate = useNavigate();

    //Login 
    const login = () => {
        navigate("/user/")
    }

    return (
        <div className="modal_section">
            <div className="centered_modal login">
                <div className="modal_header">
                    <img className="img-fluid" src="/logo.png" alt="logo" />
                    <div className="modal_close_icon" onClick={() => navigate("/public/")}><i className="fa-solid fa-xmark"></i></div>
                </div>
                <div className="modal_body">
                    <div className="title">
                        <h3>Login</h3>
                    </div>
                    <div className="input_group_main">
                        <div className="input_group">
                            <input type="text" name="userId" className="form-control" autocomplete="off" required />
                            <label className="input_label">Enter User Id</label>
                        </div>
                    </div>
                    <div className="input_group_main">
                        <div className="input_group">
                            <input type="password" name="password" className="form-control" autocomplete="off" required />
                            <label className="input_label">Enter Password</label>
                        </div>
                    </div>
                </div>
                <div className="modal_footer">
                    <button onClick={() => login()}>Login</button>
                </div>
                <p>Don't have an account? <span onClick={() => navigate("/public/sign-up")}>Sign Up</span></p>
            </div>
        </div>
    )
}

export default LogIn