import React from 'react'

const NewsLetter = () => {
    return (
        <div className="row news_letter">
            <div className="col-12">
                <h2>Stay Stunned With Us</h2>
                <div className="news_letter_input_mian">
                    <div className="input_group">
                        <input type="text" name="email" className="form-control" required />
                        <label className="input_label">Enter E-mail</label>
                        <button>Subscribe</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsLetter