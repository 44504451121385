import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TwoYearsProgrammeArray } from '../../../arrays/two-year-programme-array/TwoYearsProgrammeArray';

const TwoYearsProgramme = () => {
    const navigate = useNavigate();
    
    return (
        <div className="row pricing_card_section ">
            <div className="heading mb-2">
                        <h2>For 11th and 12th <span>JEE, NEET, NDA, MATCET</span></h2>
                    </div>
             {/* <div className="col-lg-2 col-md-2 pricing_card_main" ></div> */}
            {TwoYearsProgrammeArray?.map((items, index) => {
                return (
                    <div className="col-lg-3 col-md-3 pricing_card_main" key={index}>
                        <div className="pricing_card">
                            <div className="card">
                                <div className="card_header">
                                    <h6>Pricing Plan For</h6>
                                    <h6>{items?.courseType}</h6>
                                    <h6><i className="fa-solid fa-indian-rupee-sign"></i>{items?.price}</h6>
                                </div>
                                <div className="feature_list">
                                    {items?.featureList?.map((list, index) => {
                                        return (
                                            <div className="list_item" key={index}>
                                                <i className="fa-solid fa-check"></i>
                                                <p>{list}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="card_button pricing_card_button" style={{ width: "75%" }}>
                                    <button onClick={() => navigate("/public/mobile-verification")}>Get Started</button>
                                    <div className="arrow_icon">
                                        <i className="fa-solid fa-arrow-right" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
                 {/* <div className="col-lg-1 col-md-2 pricing_card_main" ></div> */}
        </div>
    )
}

export default TwoYearsProgramme