import React from 'react'

const ClassesHeroSection = () => {
    return (
        <main className="hero_section">
            <div className="container">
                <div className="row">
                    
                    <div className="col-lg-6 col-md-6 hero_section_text_main">
                        <div>

                        <h1>Explore Our <br />Courses</h1>
                        <p>Explore our diverse courses with Sollverz.
                        Unlock your potential and achieve academic excellence.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 hero_section_img">
                        <img src="/images/classes-img/hero-section-img.png" className="img-fluid" alt="hero section img" />
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ClassesHeroSection