import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardList,
  faUserPen,
  faComments,
  faCalendarDays,
  faCircleExclamation,
  faFilePowerpoint,
  faHeadset,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

const Courses = () => {
  const advantageInfo = [
    {
      icon: <FontAwesomeIcon icon={faClipboardList} />,
      heading: "Planning and Preparation",
    },
    {
      icon: <FontAwesomeIcon icon={faUserPen} />,
      heading: "Doubt Solving Sessions",
    },
    {
      icon: <FontAwesomeIcon icon={faComments} />,
      heading: "Interactive Session",
    },
    {
      icon: <FontAwesomeIcon icon={faCalendarDays} />,
      heading: "Test Practice Schedule ",
    },
    {
      icon: <FontAwesomeIcon icon={faCircleExclamation} />,
      heading: "Errors Solving",
    },
    {
      icon: <FontAwesomeIcon icon={faFilePowerpoint} />,
      heading: "Presentation Creation",
    },
    {
      icon: <FontAwesomeIcon icon={faHeadset} />,
      heading: "Counseling and support ",
    },
    { icon: <FontAwesomeIcon icon={faUsers} />, heading: "Meditation Session" },
  ];
  return (
    <div>
      <main className="hero_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 hero_section_text_main">
              <div
                className="hero_section_text"
                data-aos="zoom-in-down"
                data-aos-duration="2000"
              >
                <h1>
                  Build Skills With Courses and Certificates from{" "}
                  <span>Sollverz</span>
                </h1>
                <p>
                  Explore our diverse courses with Sollverz. Unlock your
                  potential and achieve academic excellence.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 hero_section_img mt-2">
              <img
                src="/images/courses_img/img_1.jpg"
                className="img-fluid"
                alt="hero section img"
              />
            </div>
          </div>
        </div>
      </main>
      <div className="container">
        <div className="row  mt-3">
          <div className="col-12">
            <div className="heading">
              <h2>
                Advantage to being with <span>Sollverz</span>{" "}
              </h2>
            </div>
          </div>
          <div className="steps_section_wrapper">
            <div className="row gy-3 gy-md-4 mb-5">
              {advantageInfo.map((data, index) => (
                <div className="col-lg-3 col-md-6 col-12" key={index}>
                  <div className="card_hero_section card_hero">
                    <div className="card-body">
                      <div className="me-3 text-primary">
                        <div className="hero_icon">{data?.icon}</div>
                      </div>
                      <h5>{data?.heading}</h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div>
          <h2>Our Courses</h2>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <img
              src="/images/courses_img/img1.jpg"
              className="img-fluid"
              alt="hero section img"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="courses_content_title">
              <h5>NEET (UG)</h5>
              </div>
              <p>
                NEET-UG (National Eligibility cum Entrance Test - Undergraduate)
                is a national-level entrance examination in India for students
                aspiring to pursue undergraduate medical (MBBS) and dental (BDS)
                courses in government or private colleges across the country.
                <br />
                NEET-UG is conducted by the National Testing Agency (NTA) and is
                held once a year. The exam evaluates candidates knowledge in
                subjects such as Physics, Chemistry, and Biology (Botany and
                Zoology). It consists of multiple-choice questions and is held
                in offline mode.
              </p>
              <div className="courses_content_title">
              <h5>JEE (MAINS/ADVANCE)</h5>
              </div>
              <p>JEE (Joint Entrance Examination) Main and JEE Advanced are two important exams in India for admission to undergraduate engineering programs in prestigious institutes like the Indian Institutes of Technology (IITs). JEE Main is the first stage of the exam and is conducted by the National Testing Agency (NTA) twice a year.<br/>
              JEE Advanced is the second stage & is conducted by one of the IITs for those who qualify JEE Main. Admission in JEE Advanced opens the opportunity for admission to the IITs and other prestigious engineering institutes in India.</p>
            </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="courses_content_title">
              <h5>KVPY - INSPIRE</h5>
              </div>
              <p>
              KVPY (Kishore Vaigyanik Protsahan Yojana) is a scholarship program in India that is funded by GOI. It aims to encourage students to pursue a career in science & research by providing them financial aid.
              This annual aptitude test is conducted to identify talented students who have a keen interest in the field of Basic Sciences.
                <br />
                The aptitude test consists of multiple-choice questions and is conducted in two streams: Stream SA for students studying in Class 11th, and Stream SX for students studying in Class 12th or those who have completed Class 12th with Science subjects.
              </p>
              <div className="courses_content_title">
              <h5>OLYMPIAD</h5>
              </div>
              <p>Olympiads refer to competitive examinations conducted at the international or national level to test the knowledge and skills of students in various academic disciplines. These exams are often held in subjects like Mathematics, Science, Computer Science, English, and more.
              National Olympiads are conducted within individual countries and serve as platforms to identify and nurture talented students in specific subjects.<br/>
              Participating in Olympiads can provide students with opportunities to enhance their problem-solving skills, gain recognition, and even open doors to prestigious scholarships, academic programs, or research opportunities.</p>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
            <img
              src="/images/courses_img/home_hero2.jpg"
              className="img-fluid"
              alt="hero section img"
            />
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Courses;
