import React from "react";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
    const navigate = useNavigate();

    return (
        <main>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 hero_section_text_main">
                        <div className="hero_section_text" data-aos="zoom-in-down" data-aos-duration="2000">
                            <h1>Turn Your <br /> Ambition Into a Success Story <br /> With <span>Sollverz</span></h1>
                            <p>Build Skills With Courses and Certificates<br /> from Best Tutors</p>
                            <div className="button_main">
                                <button onClick={() => navigate("pricing")}>See Pricing Plans</button>
                                <div className="arrow_icon">
                                    <i className="fa-solid fa-arrow-right" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 hero_section_img">
                    <img src="/images/home-img/home_hero2.jpg" alt="main-gif" />
                    </div>
                </div>
            </div>
        </main>
    );
}

export default HeroSection;