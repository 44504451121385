import React from 'react'
import HeroSection from "./HeroSection";
import HowItWorksSection from './HowItWorksSection';
import LearningPlatformSection from './LearningPlatformSection';
import LearnerCounter from './LearnerCounter';
import AchieveGoalsSection from './AchieveGoalsSection';
import ApplicationPlatformSection from './ApplicationPlatformSection';
import InvestingCardSection from './InvestingCardSection';

const HomePage = () => {
    return (
        <div>
            <HeroSection />
            <HowItWorksSection />
            <LearningPlatformSection />
            <LearnerCounter />
            <AchieveGoalsSection />
            <ApplicationPlatformSection />
            <InvestingCardSection />
        </div>
    )
}

export default HomePage