import React, { useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
const PublicHeader = () => {
    const [isOpen, setIsOpen] = useState(false);
    const header = useRef(null);

    const handleNavIconClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header
            ref={header}
            className={`main_header ${isOpen ? 'open' : ''}`}
            style={{ width: '100%' }}
        >
              <div className="container">
                <div className="row public_header">
                    <div className="col-lg-3 col-md-3 logo">
                        <NavLink to="/">
                        <img src="/Sollverz logo White 2.png" alt="logo" />
                        </NavLink>
                    </div>
                    <div className="col-lg-9 col-md-9">
                    <nav className={`navbar-links ${isOpen ? 'link-toggler' : ''}`}>
                            <ul>
                                <NavLink to="/">
                                    <li>Home</li>
                                </NavLink>
                                <NavLink to="/classes">
                                    <li>Classes</li>
                                </NavLink>
                                <NavLink to="/scholarship">
                                    <li>Scholarships</li>
                                </NavLink>
                                <NavLink to="/pricing">
                                    <li>Pricing</li>
                                </NavLink>
                                <NavLink to="/contact-us">
                                    <li>Contact Us</li>
                                </NavLink>
                                {/* <NavLink to="/public/mobile-verification">
                                    <button className="header_button">Sign Up</button>
                                </NavLink>
                                <NavLink to="/public/login">
                                    <button className="header_button">Log In</button>
                                </NavLink> */}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="hamburger-menu" onClick={handleNavIconClick}>
                <div id="nav-icon" className={isOpen ? 'open' : ''}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </header>
    );
};

export default PublicHeader;
