import React, { useState } from 'react';

const LearningPlatformSection = () => {

    const [list] = useState([
        {
            icon: "fa-regular fa-square-check",
            text: "Learn from Top Instructors Gain knowledge from industry-leading experts who bring real-world experience and cutting-edge techniques to the table."
        },
        {
            icon: "fa-regular fa-square-check",
            text: "Top Courses for You Explore our handpicked selection of top courses designed to your career goals. Find the perfect fit to enhance skills and advance your career."
        },
        {
            icon: "fa-regular fa-square-check",
            text: "Offline Classes Experience our hands-on for a more interactive learning experience.Connect directly with instructors and peers while engaging in practical sessions."
        }
    ]);

    return (
        <div className="learning_platform_section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <img src="/images/home-img/tree-img.png" alt="tree img" />
                    </div>
                    <div className="col-lg-6 col-md-6 learning_platform_content">
                        <div>
                            <h2 style={{color:"#9A1E5E"}}>Know About Our Learning <br />Platform</h2>
                            <p>Discover a world of possibilities with our comprehensive learning platform.  From beginner to advanced levels, our growing selection offers a diverse range of courses tailored to fit your career goals.Free E-Book, Video, and Consultation<br/>
                            Unlock your potential with our free e-book, video resources, and personal consultation. Dive into expert insights and personalized guidance from top instructors to get started on your learning journey.
                            </p>
                            <ul style={{fontSize:"14px"}}>
                                {list?.map((listItem, index) => {
                                    return (
                                        <li key={index}><i className={listItem?.icon}></i>{listItem?.text}</li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LearningPlatformSection