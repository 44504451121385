import React from 'react';
import { useNavigate } from 'react-router-dom';
import { scholarshipTestCardArray } from '../../../arrays/scholarship-page-array/scholarshipTestCardArray';

const ScholarshipTestCard = () => {
    const navigate = useNavigate();

    return (
        <div className="scholarship_test_card_main">
            {scholarshipTestCardArray?.map((items, index) => {
                return (
                    <div className={`row scholarship_test_card ${items?.testType === "Foundation Test" ? "flex-row-reverse" : ""}`} style={{ backgroundImage: `url(${items?.bgImage})` }} key={index}>
                        <div className="col-lg-6 col-md-6 card_image" style={{ justifyContent: `${items?.testType === "Foundation Test" ? "end" : "start"}` }}>
                            <img src={items?.img} alt={items?.alt} className="img-fluid" />
                        </div>
                        <div className="col-lg-6 col-md-6 card_content" style={{ paddingLeft: `${items?.testType === "Foundation Test" ? "10%" : ""}` }}>
                            <div>
                                <div className="course_type">
                                    <p>{items?.testType}</p>
                                </div>
                                <h6>{items?.classes}</h6>
                                <h3>{items?.text}</h3>
                                <div className="card_button test_card_button">
                                    <button onClick={() => navigate("/public/mobile-verification")}>Join Now</button>
                                    <div className="arrow_icon">
                                        <i className="fa-solid fa-arrow-right" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}

export default ScholarshipTestCard