export const pricingCardforVIIIStd =[
    {
        courseType: "Regular Batch for 8th STD",
        price: 35000,
        featureList: ["Video Classes", "Expert Faculties", "Learning App", "Test Series"],
    },
    {
        courseType: "Regular + Foundation for 8th STD",
        price:70000,
        featureList: ["Video Classes", "Expert Faculties", "Learning App", "Test Series"],
    },
    {
        courseType: "Foundation for 8th STD",
        price:35000,
        featureList: ["Video Classes", "Expert Faculties", "Learning App", "Test Series"],
    },
]