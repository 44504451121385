import React from 'react'
import { ReviewArray } from '../../../arrays/Review-page-array/ReviewArray'

const Review = () => {
    return (
        <>
            <main className="hero_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 hero_section_text_main">
                            <div>
                                <h1>Review</h1>
                                <p>Our Users Satisfied using Sollverz and want to share their experience to you</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 hero_section_img">
                            <img src="/images/review-img/reviewImage.png" className="img-fluid" alt="hero section img" />
                        </div>
                    </div>
                </div>
            </main>

            <div className='second_fold'>
                <div className='container'>
                    <div className='row'>
                        <div className='heading'>
                            <h2>What our students say about <span>Sollverz</span></h2>
                        </div>



                        <div className='row student_review_section'>
                            {ReviewArray?.map((items, index) => {
                                return (
                                    <div className='col-md-3 col-lg-3' key={index}>
                                        <div className="student_review_card">
                                            <div className="icon_outer_layer">
                                                <div className="icon_inner_layer">
                                                    <img src={items?.img} className="img-fluid" alt={items?.alt} />
                                                </div>
                                            </div>
                                            <div className="review_content">
                                                <div className="student_name">
                                                    <h3>{items?.title}</h3>
                                                    <h5>{items?.class}</h5>
                                                </div>

                                                <div className="review_text">
                                                    <p>{items?.content}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                    </div>



                </div>



            </div>

            {/* <div className='row'>
                <div className='headind'>
                    <h2>What Parents say about<span>Sollverz</span></h2>
                </div>
            </div> */}
            {/* <div className='row student_review_section'>
                <div className='col-md-4 col-lg-4'>
                    <div className="student_review_second">
                        <div className="icon_outer_layer">
                            <div className="icon_inner_layer">
                                <img src='./images/review-img/card_3.png' />
                            </div>
                        </div>
                        <div className="student_name">
                            <h3> Nancy Walker</h3>

                            <div className="review_content">
                                <div className="review_text">
                                    <p>Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the indus
                                        try's standard dummy text ever</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Review