import React, { useState } from "react";
import { useNavigate } from "react-router-dom";


const EmailVerification = () => {

    const navigate = useNavigate();
    const [showOtpVerification, setShowOtpVerification] = useState(false);

    //Number Verification
    const emailVerification = () => {
        setShowOtpVerification(true);
    }

    //OTP Verification
    const otpVerification = () => {
        navigate("/public/sign-up");
    }


    return (
        <div className="modal_section">
            <div className="centered_modal">
                <div className="modal_header">
                    <img className="img-fluid" src="/logo.png" alt="logo" />
                    <div className="modal_close_icon" onClick={() => navigate("/public/")}><i className="fa-solid fa-xmark"></i></div>
                </div>
                <div className="modal_body">
                    <div className="title">
                        <h3>Email Verification</h3>
                    </div>
                    <div className="input_group_main">
                        <div className="input_group">
                            <input type="text" name="email" className="form-control" autocomplete="off" required />
                            <label className="input_label">Enter E-mail</label>
                            <button onClick={() => emailVerification()}>Send OTP</button>
                        </div>
                    </div>
                    {showOtpVerification &&
                        <div>
                            <p>Enter 4 digit OTP we have sent on xyz@gmail.com</p>
                            <div className="otp_verification">
                                <input type="number" className="form-control" />
                                <input type="number" className="form-control" />
                                <input type="number" className="form-control" />
                                <input type="number" className="form-control" />
                            </div>
                            <p>Did not received OTP? <span>Resend OTP</span></p>
                        </div>
                    }
                </div>
                <div className="modal_footer">
                    <button onClick={() => otpVerification()}>Continue</button>
                </div>
            </div>
        </div>
    );
}

export default EmailVerification;