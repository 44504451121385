import React from 'react';

const AvailedScholarshipStudentsCard = ({ type, availedScholarshipStudents }) => {

    return (
        <div id={type} className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                {availedScholarshipStudents?.map((items, index) => {
                    return (
                        <div className="carousel-item active" key={index}>
                            <div className="row">
                                {items?.studentDetail?.map((data, index) => {
                                    return (
                                        <div className="col-lg-3 col-md-3 scholarship_availed_student_main" key={index}>
                                            <div className="card">
                                                <div className="card_image">
                                                    <img className="img-fluid" src={data?.img} alt={data?.alt} />
                                                </div>
                                                <h6 className="name">{data?.name}</h6>
                                                <h6 className="marks">{`Marks - ${data?.marks}`}</h6>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target={`#${type}`} data-bs-slide="prev">
                <i className="fa-solid fa-chevron-right"></i>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target={`#${type}`} data-bs-slide="next">
                <i className="fa-solid fa-chevron-right"></i>
            </button>
        </div>
    )
}

export default AvailedScholarshipStudentsCard