import React from 'react';
import { useNavigate } from 'react-router-dom';

const ClassesCard = ({ filteredClasses }) => {
    const navigate = useNavigate();

    return (
        <>
            {filteredClasses?.map((items, index) => {
                return (
                    <div className="course_card" key={index}>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 course_img_main">
                                <div className="course_img">
                                    <img src={items?.img} className="img-fluid" alt={items?.alt} />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8">
                                <div className="course_content">
                                    <div className="course_type">
                                        <p style={{ margin: "0" }}>Pre Foundation</p>
                                    </div>
                                    <div className="course_name">
                                        <h3>{items?.courseName}</h3>
                                        <p>{items?.class}</p>
                                    </div>
                                    <div className="course_detail_main">
                                        <div className="course_detail">
                                            <i className="fa-solid fa-signal"></i>
                                            <p>All Levels</p>
                                        </div>
                                        <div className="course_detail">
                                            <i className="fa-solid fa-file"></i>
                                            <p>{`${items?.lessons} Lessons`}</p>
                                        </div>
                                        <div className="course_detail">
                                            <i className="fa-solid fa-circle-question"></i>
                                            <p>{`${items?.quiz} Quiz`}</p>
                                        </div>
                                        <div className="course_detail">
                                            <i className="fa-solid fa-users"></i>
                                            <p>{`${items?.students} Students`}</p>
                                        </div>
                                    </div>
                                    <div className="course_paragraph">
                                        <p>{items?.content}</p>
                                    </div>
                                    <div className="card_button course_card_button">
                                        <button onClick={() => navigate("/public/pricing")}>Enroll Now!</button>
                                        <div className="arrow_icon">
                                            <i className="fa-solid fa-arrow-right" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    )
}

export default ClassesCard