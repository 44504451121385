import React from 'react'



const About = () => {

    return (
        <>
            <main className="hero_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 hero_section_text_main">
                            <div>
                                <h1>Know more about <span>Sollverz</span></h1>
                                <p>We are here to Build Student career</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 hero_section_img">
                            <img src="/images/aboutUs/4.png" clasName="img-fluid" alt="hero section img" />
                        </div>
                    </div>
                </div>
            </main>
            
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-5 col-md-5 col-12 online_education_img' >

                        <img src='/images/aboutUs/about_hero.jpg' alt='onlineEducation' />

                    </div>
                    <div className='col-lg-7 col-md-7 col-12 online_education'>
                        <div>
                            <h2>Our Approach</h2>
                            <p>Sollverz Academy, where technology meets education! We are proud to introduce a cutting-edge approach to 
                                learning, combining the power of virtual reality with our team of highly skilled tutors for coaching
                                of competitive exams like JEE Mains (Advance), NEET - UG for 11th and 12th students and Foundation 
                                courses like KVPY, Olympiad for class 9th and 10th.</p>
                            <p>
                            At Solverz Academy, we understand that traditional classroom settings may not always cater to individual learning needs. 
                            That's why we have harnessed the transformative potential of virtual reality technology to revolutionize
                            the learning experience. Through immersive virtual environments, students can explore and engage with 
                            subjects like never before, making education an exciting and interactive journey.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row '>
                    <div className='col-lg-7 col-md-7 col-12 mt-5 mb-5 certification_section'>
                        <div >
                            {/* <h2>Get Your Quality Skills Certificate Through Scholarship Exam</h2> */}
                            <p>But that's not all—we believe that the guidance of a skilled tutor is essential for comprehensive learning.
                            Our team of dedicated tutors are experts in their respective fields, equipped with deep knowledge, 
                            experience of decades, thus helping the student to crack IIT's and best of Engineering and Medical 
                            Colleges.</p>
                            <p>
                            With VR, students can dive into realistic simulations, participate in interactive experiments, and gain
                            hands-on experience, all from the comfort of our state-of-the-art learning facility.</p>
                            <p>Join us on this extraordinary educational journey where learning comes to life through virtual 
                            reality and the expertise of our exceptional tutors. Enroll today and experience education in a
                            whole new dimension at Solverz Academy!!!</p>
                        </div>
                    </div>
                    <div className='col-lg-5 col-md-5 col-12 mt-3 mb-5 certification_img'>

                        <img src='/images/aboutUs/about_section.jpg' alt='Certification_img' />

                    </div>
                </div>
            </div>

            {/* <div className='container mb-3'>
                <div className='card_section'>
                    <div className='card_about'>
                        <div className='diamond'>

                        </div>
                        <div className='card_learnsollverz'>
                            <h3>Every one deserves the chance to learn with <span className='sollverz_about'>Sollverz</span></h3>
                            <p>Join now to receive personalised recommendation from the Sollverz.</p>
                            <div className="card_button start_learning_button">
                                <button>Start Learning Now</button>
                                <div className="arrow_icon">
                                    <i className="fa-solid fa-arrow-right" />
                                </div>
                            </div>
                        </div>
                        <div className='circle1 rounded-circle'>
                            <div className='circle2'></div>
                        </div>
                    </div>
                </div>

            </div> */}

            {/* <div className='container'>
                <div className='faq_div_main'>
                    <div className='faq_div'>
                        <h3>FAQ</h3>
                    </div>
                </div>
                <div className='according_start'>
                    <div className='accordion_card'>
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        Accordion Item #1
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        Accordion Item #2
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> className. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        Accordion Item #3
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

        </>
    )
}

export default About