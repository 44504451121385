import React from 'react'

const ExploreCourse = () => {
    return (
        <div className="row">

            <div className="col-12">
                <h2>Explore Our Courses</h2>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-lg-4">
                        <div className="course_main">
                            <div className='course_box_align'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ExploreCourse