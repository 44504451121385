import React from 'react'
import {achieveGoalsCardArray} from "../../../arrays/home-page-array/AchieveGoalsCardArray";

const AchieveGoalsSection = () => {

    return (
        <div className="achieve_goals_section">
            <div className="container">
                <div className="heading">
                    <h2>Achieve Your Goals with <span>Sollverz</span></h2>
                </div>
                <div className="row">
                    {achieveGoalsCardArray?.map((items, index) => {
                        return (
                            <div className="col-lg-6 col-md-6 achieve_goals_card_main" key={index}>
                                <div className="achieve_goals_card">
                                    <div className="achievement_img_heading">
                                    <div className="icon" style={{ backgroundColor: items?.bgColor }}>
                                        <img src={items?.img} alt={items?.alt} />
                                    </div>
                                        {/* <p>{items?.heading}</p> */}
                                    </div>
                                    <div className="achieve_goals_content">
                                        <h3 style={{ color: items?.color }}>{items?.title}</h3>
                                        <p>{items?.paragraph}</p>
                                    </div>
                                    <div className="hover_effect" style={{ backgroundColor: items?.hoverColor }}></div>
                                </div>
                            </div>
                        );
                    })}


                </div>
            </div>
        </div>
    )
}

export default AchieveGoalsSection