import React from 'react'
import CockpitBatch from './CockpitBatch'
import GetScholarshipCard from './GetScholarshipCard'
import PrisingHeroSection from './PrisingHeroSection'
import TrialCard from './TrialCard'
import PricingCardVIIStd from './PricingCardVIIStd'
import PricingCardXISTD from './PricingCardXISTD'
import PricingCardXStd from './PricingCardXSTD'
import TwoYearsProgramme from './TwoYearsProgramme'

const PricingPage = () => {
    return (
        <>
            <PrisingHeroSection />
            <div className="pricing_main">
                <div className="container">
                    <div className="heading">
                        <h2>Pricing <span>Plans</span></h2>
                    </div>
                    {/* <PricingCard /> */}
                    <PricingCardVIIStd/>
                    <PricingCardXISTD/>
                    <PricingCardXStd/>
                    <CockpitBatch/>
                    <TwoYearsProgramme/>
                    <TrialCard />
                    <GetScholarshipCard />
                </div>
            </div>
        </>
    )
}

export default PricingPage