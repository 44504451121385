import { createSlice } from "@reduxjs/toolkit";

const loader = createSlice({
    name: "loader",
    initialState: {
        showHideLoader: false
    },
    reducers: {
        showLoader: (state) => {
            state.showHideLoader = true
        },
        hideLoader: (state) => {
            state.showHideLoader = false
        }
    }
})

export const { showLoader, hideLoader } = loader.actions;
export default loader.reducer;